import "pannellum";

import "./VR.scss";
import { useEffect } from "react";

export default function VR({ id, panorama }) {
    useEffect(() => {
        if (id && panorama) {
            window.pannellum.viewer(id, {
                autoLoad: true,
                showControls: true,
                type: "equirectangular",
                panorama: panorama,
            });
        }
    }, [id, panorama]);

    return <div className="vr" id={id} />;
}
