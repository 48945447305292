import { useContext } from "react";

import Frame from "../../../../components/main/frame/Frame";
import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";

import RenderImage from "../../../../static/images/contents/master-plan/render.jpg";
import { ReactComponent as Phase3Svg } from "../../../../static/images/contents/master-plan/polygons/phase_3.svg";
import { ReactComponent as Phase4Svg } from "../../../../static/images/contents/master-plan/polygons/phase_4.svg";

import { MainContext } from "../../Main";

import Phase3 from "../phase-3/Phase3";
import Phase4 from "../phase-4/Phase4";

export default function MasterPlan() {
    const [changeContent] = useContext(MainContext);

    return (
        <div className="master-plan" style={{ width: "100%", height: "100%" }}>
            <Frame
                image={RenderImage}
                wrapper={
                    <SvgAreas
                        areas={[
                            {
                                Polygon: Phase3Svg,
                                className: null,
                                label: {
                                    text: "Phase 3",
                                    coords: [1120, 520],
                                },
                                isInfoboxClickableOnMobile: false,
                                onClick: () =>
                                    changeContent(<Phase3 />, {
                                        button: "menu_phases",
                                        element: "menu_phases_phase_3",
                                        head_title: "menu_phases_phase_3",
                                    }),
                            },
                            {
                                Polygon: Phase4Svg,
                                className: null,
                                label: {
                                    text: "Phase 4",
                                    coords: [1590, 500],
                                },
                                isInfoboxClickableOnMobile: false,
                                onClick: () =>
                                    changeContent(<Phase4 />, {
                                        button: "menu_phases",
                                        element: "menu_phases_phase_4",
                                        head_title: "menu_phases_phase_4",
                                    }),
                            },
                        ]}
                    />
                }
            />
        </div>
    );
}
