import { motion } from "framer-motion";

import "./Modal.scss";
import { modal_animation } from "./animations";

import { ReactComponent as CrossSVG } from "../../static/images/icons/cross.svg";

export default function Modal({ children, closeFunc }) {
    return (
        <motion.div className="modal" {...modal_animation}>
            <div className="modal-exit" onClick={closeFunc}>
                <CrossSVG />
            </div>
            {children}
        </motion.div>
    );
}
