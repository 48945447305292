import SvgSpots from "../../../../components/main/wrappers/svg-dots/SvgSpots";

import VR from "../../../../components/main/vr/VR";

import WalkingAreaPanorama from "../../../../static/images/contents/phase-3/panoramas/Cam_012_7k_opt_6.jpg";
import Phase_3_4_OverviewPanorama from "../../../../static/images/contents/phase-3/panoramas/Cam_014_7k_opt_6.jpg";
import BeachPanorama from "../../../../static/images/contents/phase-3/panoramas/Cam_017_7k_opt_6.jpg";
import KidsPlayAreaPanorama from "../../../../static/images/contents/phase-3/panoramas/Cam_013_7k_opt_6.jpg";
import RetailViewPanorama from "../../../../static/images/contents/phase-3/panoramas/Cam_016_7k_opt_6.jpg";
import BoulevardPanorama from "../../../../static/images/contents/phase-3/panoramas/Cam_015_7k_opt_6.jpg";

export const phase3Stops = (setModalContent) => [
    {
        index: 4,
        wrapper: (
            <SvgSpots
                spots={[
                    {
                        coords: [1378, 540],
                        label: { text: "Walking Area" },
                        onClick: () =>
                            setModalContent(
                                <VR
                                    id="walking-area"
                                    panorama={WalkingAreaPanorama}
                                />
                            ),
                    },
                    {
                        coords: [1750, 765],
                        label: { text: "Phase 3 & 4 Overview", width: 177 },
                        onClick: () =>
                            setModalContent(
                                <VR
                                    id="phase-3-4-overview"
                                    panorama={Phase_3_4_OverviewPanorama}
                                />
                            ),
                    },
                    {
                        coords: [1420, 925],
                        label: { text: "Beach", width: 65 },
                        onClick: () =>
                            setModalContent(
                                <VR id="beach" panorama={BeachPanorama} />
                            ),
                    },
                    {
                        coords: [1210, 780],
                        label: { text: "Kids Play Area", width: 126 },
                        onClick: () =>
                            setModalContent(
                                <VR
                                    id="kids-play-area"
                                    panorama={KidsPlayAreaPanorama}
                                />
                            ),
                    },
                    {
                        coords: [1230, 975],
                        label: { text: "Retail View", width: 101 },
                        onClick: () =>
                            setModalContent(
                                <VR
                                    id="retail-view"
                                    panorama={RetailViewPanorama}
                                />
                            ),
                    },
                    {
                        coords: [835, 585],
                        label: { text: "Boulevard" },
                        onClick: () =>
                            setModalContent(
                                <VR
                                    id="boulevard"
                                    panorama={BoulevardPanorama}
                                />
                            ),
                    },
                ]}
            />
        ),
    },
    {
        index: 34,
        wrapper: <span>Wrapper 3</span>,
    },
    {
        index: 62,
        wrapper: <span>Wrapper 4</span>,
    },
    {
        index: 94,
        default: true,
        wrapper: (
            <SvgSpots
                spots={[
                    {
                        coords: [1050, 295],
                        label: { text: "Walking Area" },
                        onClick: () =>
                            setModalContent(
                                <VR
                                    id="walking-area"
                                    panorama={WalkingAreaPanorama}
                                />
                            ),
                    },
                    {
                        coords: [1280, 165],
                        label: { text: "Phase 3 & 4 Overview", width: 177 },
                        onClick: () =>
                            setModalContent(
                                <VR
                                    id="phase-3-4-overview"
                                    panorama={Phase_3_4_OverviewPanorama}
                                />
                            ),
                    },
                    {
                        coords: [1367, 305],
                        label: { text: "Beach", width: 65 },
                        onClick: () =>
                            setModalContent(
                                <VR id="beach" panorama={BeachPanorama} />
                            ),
                    },
                    {
                        coords: [1285, 410],
                        label: { text: "Kids Play Area", width: 126 },
                        onClick: () =>
                            setModalContent(
                                <VR
                                    id="kids-play-area"
                                    panorama={KidsPlayAreaPanorama}
                                />
                            ),
                    },
                    {
                        coords: [1525, 475],
                        label: { text: "Retail View", width: 101 },
                        onClick: () =>
                            setModalContent(
                                <VR
                                    id="retail-view"
                                    panorama={RetailViewPanorama}
                                />
                            ),
                    },
                    {
                        coords: [1065, 605],
                        label: { text: "Boulevard" },
                        onClick: () =>
                            setModalContent(
                                <VR
                                    id="boulevard"
                                    panorama={BoulevardPanorama}
                                />
                            ),
                    },
                ]}
            />
        ),
    },
];
