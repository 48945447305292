import React, { useContext, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useMediaQuery } from "@react-hook/media-query";
import { motion, AnimatePresence } from "framer-motion";

import { MainContext } from "../../pages/main/Main";

import "./Infobox.scss";
import "./Adaptations.scss";
import { infobox_animation } from "./animations";

function InfoboxText({ text }) {
    return (
        <div className="infobox-text">
            <span className="infobox-text-inner">{text}</span>
        </div>
    );
}

function Infobox({ pos, inner, text, isMobileOptimized = false }) {
    const infoboxRef = useRef();

    const leftPos = isMobileOptimized
        ? "50%"
        : infoboxRef.current &&
          pos.left + infoboxRef.current.offsetWidth + 20 > window.innerWidth
        ? window.innerWidth - infoboxRef.current.offsetWidth
        : pos.left + 20;

    const topPos = isMobileOptimized
        ? "50%"
        : infoboxRef.current &&
          (pos.top + infoboxRef.current.offsetHeight / 2 > window.innerHeight
              ? window.innerHeight - infoboxRef.current.offsetHeight
              : pos.top - infoboxRef.current.offsetHeight / 2 < 0
              ? 0
              : pos.top - infoboxRef.current.offsetHeight / 2);

    return (
        <motion.div
            ref={infoboxRef}
            className="infobox"
            style={{
                left: leftPos,
                top: topPos,
                pointerEvents: "none",

                transform: isMobileOptimized
                    ? "translate(-50%, -50%)"
                    : "translate(0, 0)",
            }}
            {...infobox_animation(isMobileOptimized)}
            {...(isMobileOptimized && {
                onMouseDown: (e) => e.stopPropagation(),
            })}
        >
            {inner ? inner : text && <InfoboxText text={text} />}
        </motion.div>
    );
}

export function WithInfobox({
    children,
    inner,
    text,
    isClickableOnMobile = true,
}) {
    const [changeContent, setModalContent, pageClicked] =
        useContext(MainContext);

    const isMobile = useMediaQuery("(max-width: 800px)");

    const [isOver, setIsOver] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [cursorPos, setCursorPos] = useState({ left: 0, top: 0 });

    useEffect(() => {
        setIsClicked(false);
    }, [pageClicked]);

    function handleMouseMove(e) {
        setCursorPos({ left: e.clientX, top: e.clientY });
    }

    return (
        <>
            {createPortal(
                <AnimatePresence mode="wait">
                    {(isOver || isClicked) && (
                        <Infobox
                            pos={cursorPos}
                            inner={inner}
                            text={text}
                            isMobileOptimized={isClickableOnMobile && isMobile}
                        />
                    )}
                </AnimatePresence>,
                document.body
            )}

            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, {
                        ...(isClickableOnMobile && isMobile
                            ? {
                                  onClick: () =>
                                      setIsClicked((clicked) => !clicked),
                              }
                            : !isMobile && {
                                  onMouseEnter: () => setIsOver(true),
                                  onMouseLeave: () => setIsOver(false),
                                  onMouseMove: handleMouseMove,
                              }),
                    });
                }

                return child;
            })}
        </>
    );
}
