import { useEffect, useRef } from "react";
import "./Frame.scss";

export default function Frame({ image, wrapper }) {
    const frameRef = useRef();

    useEffect(() => {
        if (frameRef === null) return;
        const frame = frameRef.current;
        frame.scrollTop = (frame.scrollHeight - frame.clientHeight) / 2;
        frame.scrollLeft = (frame.scrollWidth - frame.clientWidth) / 2;
    }, []);

    return (
        <div className="frame" ref={frameRef}>
            <div className="frame-wrapper">
                {wrapper ? (
                    wrapper
                ) : (
                    <svg
                        style={{ height: "100%" }}
                        viewBox="0 0 1920 1080"
                        version="1.0"
                        preserveAspectRatio="xMidYMid slice"
                        xmlns="http://www.w3.org/2000/svg"
                    ></svg>
                )}
            </div>
            <img className="frame-image" src={image} alt="frame" />
        </div>
    );
}
