import React from "react";

import "./SvgAreas.scss";

import SvgLabel from "../svg-label/SvgLabel";
import { WithInfobox } from "../../../infobox/Infobox";

function SvgArea({
    Polygon,
    className,
    label,
    infoboxInner,
    isInfoboxOnMobile = false,
    onClick,
}) {
    return (
        <WithInfobox
            text={label?.text}
            inner={infoboxInner}
            isClickableOnMobile={isInfoboxOnMobile}
        >
            <Polygon
                className={`svg-area ${className ? className : ""}`}
                onClick={onClick}
                onMouseDown={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
            />
            {label && <SvgLabel {...label} />}
        </WithInfobox>
    );
}

export default function SvgAreas({ areas }) {
    return (
        <div className="svg-areas">
            <svg
                viewBox="0 0 1920 1080"
                version="1.0"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
            >
                {areas.map((area, i) => (
                    <SvgArea key={i} {...area} />
                ))}
            </svg>
        </div>
    );
}
