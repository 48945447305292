import ReactLoading from "react-loading";
import { motion } from "framer-motion";

import "./Loading.scss";
import { loading_animation } from "./animations";
import { useTranslation } from "react-i18next";

export default function PercentageLoading({ percents }) {
    const { t } = useTranslation();
    return (
        <motion.div className="percentage-loading" {...loading_animation}>
            <div className="percentage-loading-inner">
                <ReactLoading
                    type="spin"
                    color="#FFFFFF"
                    height={150}
                    width={150}
                />
                <div className="percentage-loading-title">
                    <span className="percentage-loading-title-text">
                        {t("percentage_loading_title")}
                    </span>
                </div>
                <div className="percentage-loading-progress">
                    <span className="percentage-loading-progress-text">
                        {Math.round(percents)}%
                    </span>
                </div>
            </div>
        </motion.div>
    );
}
