import React from "react";

import "./SvgSpots.scss";

import SvgLabel from "../svg-label/SvgLabel";

function SvgSpot({ coords, label, onClick }) {
    return (
        <g className="svg-spot">
            <circle
                className="svg-spot-circle"
                cx={coords[0]}
                cy={coords[1]}
                r="8"
                onClick={onClick}
                onMouseDown={(e) => e.stopPropagation()}
                onMouseUp={(e) => e.stopPropagation()}
            />
            <SvgLabel
                {...label}
                coords={[
                    coords[0] - (8.75 * label.text.length + 16) / 2,
                    coords[1] - 25,
                ]}
            />
        </g>
    );
}

export default function SvgSpots({ spots }) {
    return (
        <div className="svg-spots">
            <svg
                viewBox="0 0 1920 1080"
                version="1.0"
                preserveAspectRatio="xMidYMid slice"
                xmlns="http://www.w3.org/2000/svg"
            >
                {spots.map((spot, i) => (
                    <SvgSpot key={i} {...spot} />
                ))}
            </svg>
        </div>
    );
}
