export const button_area_animation = (isActive, disabled = false) => ({
    animate: {
        backgroundColor: isActive
            ? "#4e7393"
            : !disabled
            ? "#e1ddd5"
            : "rgba(225, 221, 213, 0.200000003)",
        color: isActive ? "#FFFFFF" : "#4c4c4c",
    },
    whileHover: {
        backgroundColor: isActive ? "#476885" : !disabled ? "#d4d0c8" : null,
    },
    style: {
        cursor: isActive || !disabled ? "pointer" : "auto",
    },
});

export const button_area_arrow_animation = (isExpanded, isMobile) => ({
    animate: { rotate: isExpanded ? (!isMobile ? 90 : 180) : 0 },
    transition: { duration: !isMobile ? 0.2 : 0.3 },
});

export const button_elements_animatation = (isMobile) => ({
    initial: {
        opacity: 0,
        transform: !isMobile
            ? "translate(180px, calc(-50% - 16px))"
            : "translate(calc(50px - 50%), calc(-32px - 10px - 100%)",
    },
    animate: {
        opacity: 1,
    },
    exit: {
        opacity: 0,
    },
});
