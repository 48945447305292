import { useContext } from "react";
import Reel from "../../../../components/main/reel/Reel";
import { MainContext } from "../../Main";

import { phase4Stops } from "./stops";

export default function Phase4() {
    const [changeContent, setModalContent] = useContext(MainContext);

    return (
        <div className="phase-4" style={{ width: "100%", height: "100%" }}>
            <Reel
                folder="static/images/contents/phase-4/renders"
                amount={120}
                qualitySteps={4}
                defaultControlsPart={-0.05}
                stops={phase4Stops(changeContent, setModalContent)}
            />
        </div>
    );
}
