import { useEffect, useState } from "react";

import IconButton from "./IconButton";

import { ReactComponent as FullscreenIcon } from "../../../../static/images/icons/fullscreen.svg";
import { ReactComponent as Fullscreen2Icon } from "../../../../static/images/icons/fullscreen_2.svg";

export default function FullscreenButton({ pos }) {
    const [isFullScreen, setIsFullScreen] = useState(false);

    useEffect(() => {
        const handleFullScreenChange = () => {
            setIsFullScreen(!!document.fullscreenElement);
        };

        document.addEventListener("fullscreenchange", handleFullScreenChange);

        return () => {
            document.removeEventListener(
                "fullscreenchange",
                handleFullScreenChange
            );
        };
    }, []);

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            document.documentElement.requestFullscreen();
        } else {
            document.exitFullscreen();
        }
    };

    return (
        <IconButton
            ActiveIcon={Fullscreen2Icon}
            DefaultIcon={FullscreenIcon}
            isActive={isFullScreen}
            onClick={toggleFullScreen}
            style={pos}
        />
    );
}
