import "./ApartmentInfo.scss";

import RegularList from "../../../layouts/lists/RegularList";

function ApartmentInfoButton({ onClick }) {
    return (
        <div className="apartment-info-button" onClick={onClick}>
            <span className="apartment-info-button-text">Review</span>
        </div>
    );
}

function ApartmentInfoSpec({ name, value, isHighlighted }) {
    return (
        <div className="apartment-info-spec">
            <div className="apartment-info-spec-name">
                <span className="apartment-info-spec-name-text">{name}</span>
            </div>
            <div className="apartment-info-spec-line" />
            <div
                className={`apartment-info-spec-value${
                    isHighlighted && " apartment-info-spec-value-highlighted"
                }`}
            >
                <span className="apartment-info-spec-value-text">{value}</span>
            </div>
        </div>
    );
}

export default function ApartmentInfo({ title, image, specs, buttonOnClick }) {
    return (
        <div className="apartment-info">
            <div className="apartment-info-image">
                <img src={image} alt="apartment-plan" />
            </div>
            <div className="apartment-info-data">
                <div className="apartment-info-title">
                    <span className="apartment-info-title-text">{title}</span>
                </div>
                <div className="apartment-info-specs">
                    <RegularList
                        items={specs}
                        ItemComponent={ApartmentInfoSpec}
                    />
                </div>
                <ApartmentInfoButton onClick={buttonOnClick} />
            </div>
        </div>
    );
}
