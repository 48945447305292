import React from "react";

import "./Overlay.scss";

export default function Overlay({ children, props, zIndex = 3 }) {
    return (
        <div className="overlay" style={{ zIndex: zIndex }}>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, { ...props });
                }

                return child;
            })}
        </div>
    );
}
