import IconButton from "./IconButton";

import { ReactComponent as GuideIcon } from "../../../../static/images/icons/guide.svg";
import { guide_button_animation } from "./animations";

export default function GuideButton() {
    return (
        <IconButton
            DefaultIcon={GuideIcon}
            style={{ backgroundColor: "#f4f8fd", position: "relative" }}
            animation={guide_button_animation}
        />
    );
}
