export const loading_animation = {
    initial: { opacity: 1 },
    exit: { opacity: 0, pointerEvents: "none" },
};

export const loading_image_animation = {
    animate: {
        clipPath: [
            "polygon(0 0,100% 0,100% 100%,0 100%)",
            "polygon(0 100%,100% 100%,100% 100%,0 100%)",
            "polygon(0 0,100% 0,100% 0,0 0)",
            "polygon(0 0,100% 0,100% 100%,0 100%)",
        ],
    },
    transition: {
        duration: 2,
        ease: "easeInOut",
        repeat: Infinity,
    },
};
