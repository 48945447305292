import { motion } from "framer-motion";

import "./IconButton.scss";
import { icon_button_animation } from "./animations";

function IconButtonLabel({ label }) {
    return (
        <div className="icon-button-label">
            <span className="icon-button-label-text">{label}</span>
        </div>
    );
}

export default function IconButton({
    DefaultIcon,
    ActiveIcon,
    onClick,
    style,
    isActive,
    animation = icon_button_animation,
    label = null,
}) {
    return (
        <motion.div
            {...animation}
            className="icon-button"
            onClick={onClick}
            style={style}
        >
            <div className="icon-button-image">
                {isActive && ActiveIcon ? <ActiveIcon /> : <DefaultIcon />}
            </div>
            {label !== null && <IconButtonLabel label={label} />}
        </motion.div>
    );
}
