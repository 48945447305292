import {
    RouterProvider,
    createBrowserRouter,
    Navigate,
} from "react-router-dom";

import "./App.scss";

import { IncludeLocalization } from "./components/localization/Localization";

import Main from "./pages/main/Main";

const router = createBrowserRouter([
    { path: "/", element: <Navigate to="/en/" replace /> },
    { path: "*", element: <Navigate to="/" replace /> },
    {
        path: "/:lang",
        element: <IncludeLocalization />,
        children: [
            {
                path: "",
                element: <Main />,
            },
        ],
    },
]);

function App() {
    return (
        <div className="App">
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
