import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";
import SvgSpots from "../../../../components/main/wrappers/svg-dots/SvgSpots";

import VR from "../../../../components/main/vr/VR";

import LagoonAndSportAreaPanorama from "../../../../static/images/contents/phase-4/panoramas/Cam_010_7k_opt_6.jpg";
import LagoonAndParkPanorama from "../../../../static/images/contents/phase-4/panoramas/Cam_011_7k_opt_6.jpg";
import RetailView2Panorama from "../../../../static/images/contents/phase-4/panoramas/Cam_009_7k_opt_6.jpg";

import { ReactComponent as ReveSvg } from "../../../../static/images/contents/phase-4/polygons/reve.svg";
import { ReactComponent as AzureSvg } from "../../../../static/images/contents/phase-4/polygons/azure.svg";

import ReveTower from "../reve-tower/ReveTower";
import AzureTower from "../azure-tower/AzureTower";

export const phase4Stops = (changeContent, setModalContent) => [
    {
        index: 12,
        wrapper: <span>Wrapper 2</span>,
    },
    {
        index: 40,
        wrapper: <span>Wrapper 3</span>,
    },
    {
        index: 68,
        wrapper: <span>Wrapper 4</span>,
    },
    {
        index: 99,
        default: true,
        wrapper: (
            <>
                <SvgAreas
                    areas={[
                        {
                            Polygon: ReveSvg,
                            className: null,
                            label: {
                                text: "RÊVE",
                                coords: [675, 215],
                                width: 60,
                            },
                            onClick: () =>
                                changeContent(<ReveTower />, {
                                    button: "menu_towers",
                                    element: "menu_towers_reve",
                                    head_title: "menu_towers_reve",
                                }),
                        },
                        {
                            Polygon: AzureSvg,
                            className: null,
                            label: {
                                text: "Azure",
                                coords: [885, 239],
                            },
                            onClick: () =>
                                changeContent(<AzureTower />, {
                                    button: "menu_towers",
                                    element: "menu_towers_azure",
                                    head_title: "menu_towers_azure",
                                }),
                        },
                    ]}
                />
                <SvgSpots
                    spots={[
                        {
                            coords: [380, 725],
                            label: {
                                text: "Lagoon and Sport Area",
                                width: 188,
                            },
                            onClick: () =>
                                setModalContent(
                                    <VR
                                        id="lagoon-and-sport-area"
                                        panorama={LagoonAndSportAreaPanorama}
                                    />
                                ),
                        },
                        {
                            coords: [480, 525],
                            label: { text: "Lagoon and Park", width: 145 },
                            onClick: () =>
                                setModalContent(
                                    <VR
                                        id="lagoon-and-park"
                                        panorama={LagoonAndParkPanorama}
                                    />
                                ),
                        },
                        {
                            coords: [660, 417],
                            label: { text: "Retail View 2", width: 114 },
                            onClick: () =>
                                setModalContent(
                                    <VR
                                        id="retail-view-2"
                                        panorama={RetailView2Panorama}
                                    />
                                ),
                        },
                    ]}
                />
            </>
        ),
    },
];
