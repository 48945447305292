import { useContext } from "react";
import Reel from "../../../../components/main/reel/Reel";
import { MainContext } from "../../Main";

import "./ReveTower.scss";
import { reveTowerStops } from "./stops";

export default function ReveTower() {
    const [changeContent, setModalContent] = useContext(MainContext);

    return (
        <div className="reve-tower" style={{ width: "100%", height: "100%" }}>
            <Reel
                folder="static/images/contents/reve-tower/renders"
                amount={120}
                qualitySteps={4}
                defaultControlsPart={0.1}
                stops={reveTowerStops(changeContent)}
                overlay_elements={null} // Soon
            />
        </div>
    );
}
