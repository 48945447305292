import { useTranslation } from "react-i18next";
import "./HeadTitle.scss";

export default function HeadTitle({ text, pos }) {
    const [t] = useTranslation();

    return (
        <div className="head-title" style={pos}>
            <span className="head-title-text">{t(text)}</span>
        </div>
    );
}
