import { useEffect, useMemo, useState } from "react";

import "./ImagesLoader.scss";
import { increaseState } from "../../../../utils/basic";

export default function ImagesLoader({
    images,
    setImages,
    folder,
    amount,
    extension,
    qualitySteps,
    checkIfHighByDefault,
    setLoadedPart,
}) {
    const [imagePaths, setImagePaths] = useState({});

    const loadImagePaths = async (quality) => {
        const setLoadedPaths = (loadedPaths) => {
            setImagePaths((prevImagePaths) => ({
                ...prevImagePaths,
                [String(quality)]: loadedPaths,
            }));
        };

        let loadedPaths = [];
        let i = 1;
        while (i <= amount) {
            try {
                // check if quality must be high as default
                let qualityForThisFrame;
                if (checkIfHighByDefault && checkIfHighByDefault(i)) {
                    qualityForThisFrame = qualitySteps;
                } else {
                    qualityForThisFrame = quality;
                }

                let importedImage = await import(
                    `../../../../${folder}/${qualityForThisFrame}/${i}${extension}`
                );
                loadedPaths.push(importedImage.default);

                if (!(i % 20)) {
                    setLoadedPaths(loadedPaths);
                }
            } catch (error) {
                console.error("Error loading image:", error);
            }
            i += 1;
        }

        setLoadedPaths(loadedPaths);
    };

    const runImageLoading = async (quality_step) => {
        await loadImagePaths(quality_step);
    };

    useEffect(() => {
        runImageLoading(1);
    }, [folder, amount, extension, qualitySteps]);

    const imagesAmount = useMemo(
        () => amount * qualitySteps,
        [amount, qualitySteps]
    );
    const [loadedAmount, setLoadedAmount] = useState(0);

    const [currentQualityIndex, setCurrentQualityIndex] = useState(1);
    const [currentImageIndex, setCurrentImageIndex] = useState(1);

    function onLoadImage() {
        if (currentImageIndex < amount) {
            setCurrentImageIndex(increaseState);
        } else {
            setCurrentImageIndex(1);
            setCurrentQualityIndex((oldQualityIndex) => {
                if (oldQualityIndex === qualitySteps) {
                    return oldQualityIndex;
                }
                const newQualityIndex = oldQualityIndex + 1;
                runImageLoading(newQualityIndex);
                return newQualityIndex;
            });
        }

        setLoadedAmount((state) => {
            let newState = state + 1;
            setLoadedPart(newState / imagesAmount);

            return newState;
        });

        setImages((images) => {
            let newImage =
                imagePaths[currentQualityIndex][currentImageIndex - 1];

            let imagesMod = [...images];
            if (imagesMod[currentImageIndex - 1]) {
                imagesMod[currentImageIndex - 1] = newImage;
            } else {
                imagesMod.push(newImage);
            }

            return imagesMod;
        });
    }

    return (
        <div className="images-loader">
            {loadedAmount !== imagesAmount &&
                imagePaths[currentQualityIndex] && (
                    <img
                        src={
                            imagePaths[currentQualityIndex][
                                currentImageIndex - 1
                            ]
                        }
                        alt="loader"
                        onLoad={onLoadImage}
                    />
                )}

            {/* Should be FIXED */}

            {images.map((image) => (
                <img key={image} src={image} alt="loader" />
            ))}
        </div>
    );
}
