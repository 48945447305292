export const icon_button_animation = {
    whileHover: { backgroundColor: "#365570" },
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
};

export const guide_button_animation = {
    ...icon_button_animation,
    whileHover: { backgroundColor: "#dbdbdb" },
};
