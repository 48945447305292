import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@react-hook/media-query";

import { AnimatePresence, motion } from "framer-motion";

import "./Menu.scss";
import "./Adaptations.scss";
import {
    button_area_animation,
    button_area_arrow_animation,
    button_elements_animatation,
} from "./animations";

import RegularList from "../../../layouts/lists/RegularList";

import { ReactComponent as DropArrowImageGrey } from "../../../static/images/icons/drop_arrow_grey.svg";
import { ReactComponent as DropArrowImageWhite } from "../../../static/images/icons/drop_arrow_white.svg";

function MenuButtonElement({
    label,
    content,
    head_title,
    t,
    buttonOnClick,
    contentDataElement,
}) {
    const isActiveElement = contentDataElement === label;

    return (
        <motion.div
            className="menu-button-element"
            {...button_area_animation(isActiveElement)}
            onClick={() => buttonOnClick(label, content, head_title)}
        >
            <span className="menu-button-element-text">{t(label)}</span>
        </motion.div>
    );
}

function MenuButton({
    title,
    disabled,
    elements,
    t,
    buttonOnClick,
    contentData,
    currentExpanded,
    setCurrentExpanded,
}) {
    const isMobile = useMediaQuery("(max-width: 800px)");

    const isSingleElement = elements.length === 1;
    const isActive = contentData?.button === title;
    const isExpanded = currentExpanded === title;

    const buttonOnClickMod = (label, content, head_title) => {
        buttonOnClick(content, {
            button: title,
            element: label,
            head_title: head_title,
        });
    };

    const buttonAreaOnClick = () => {
        if (!disabled) {
            if (!isExpanded) {
                setCurrentExpanded(title);
            } else {
                setCurrentExpanded(null);
            }
            if (isSingleElement) {
                buttonOnClickMod(
                    "",
                    elements[0].content,
                    elements[0].head_title
                );
            }
        }
    };

    return (
        <div className="menu-button">
            <motion.div
                {...button_area_animation(isActive, disabled)}
                className={"menu-button-area"}
                // data-isdisabled={disabled}
                onClick={buttonAreaOnClick}
            >
                <span className="menu-button-area-text">{t(title)}</span>
                {!isSingleElement && (
                    <motion.span
                        {...button_area_arrow_animation(isExpanded, isMobile)}
                        className="menu-button-area-arrow"
                    >
                        {isActive ? (
                            <DropArrowImageWhite />
                        ) : (
                            <DropArrowImageGrey />
                        )}
                    </motion.span>
                )}
            </motion.div>
            <AnimatePresence>
                {!isSingleElement && isExpanded && (
                    <motion.div
                        {...button_elements_animatation(isMobile)}
                        className="menu-button-elements"
                    >
                        <RegularList
                            items={elements}
                            ItemComponent={MenuButtonElement}
                            props={{
                                t: t,
                                buttonOnClick: buttonOnClickMod,
                                contentDataElement: contentData?.element,
                            }}
                        />
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}

const getHeadTitle = (title, label, head_title) => {
    return head_title ? head_title : label ? label : title;
};

export default function Menu({
    buttons,
    content,
    changeContent,
    contentClicked,
}) {
    const [t] = useTranslation();

    const [currentExpanded, setCurrentExpanded] = useState(null);

    const hideExpanded = () => setCurrentExpanded(null);
    useEffect(() => {
        hideExpanded();
    }, [contentClicked]);

    const buttonOnClick = (content, data) => {
        setCurrentExpanded(null);

        const head_title = getHeadTitle(
            data.button,
            data.element,
            data.head_title
        );
        data.head_title = head_title;

        changeContent(content, data);
    };

    useEffect(() => {
        const button_title = buttons[0].title;
        const element_label = buttons[0].elements[0].label;
        const head_title = buttons[0].elements[0].head_title;

        const content = buttons[0].elements[0].content;

        buttonOnClick(content, {
            button: button_title,
            element: element_label,
            head_title: head_title,
        });
    }, []);

    return (
        <div className="menu">
            <div className="menu-title">
                <span className="menu-title-text">{t("menu_title")}</span>
            </div>
            <div
                className="menu-buttons"
                onMouseDown={(e) => e.stopPropagation()}
            >
                <RegularList
                    items={buttons}
                    ItemComponent={MenuButton}
                    props={{
                        t: t,
                        buttonOnClick: buttonOnClick,
                        contentData: content?.data,
                        currentExpanded: currentExpanded,
                        setCurrentExpanded: setCurrentExpanded,
                    }}
                />
            </div>
        </div>
    );
}
