import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";

import { ReactComponent as Apartment1904 } from "../../../../static/images/contents/reve-tower/polygons/1904.svg";
import { ReactComponent as Apartment1905 } from "../../../../static/images/contents/reve-tower/polygons/1905.svg";
import { ReactComponent as Apartment1906 } from "../../../../static/images/contents/reve-tower/polygons/1906.svg";
import { ReactComponent as Apartment1907 } from "../../../../static/images/contents/reve-tower/polygons/1907.svg";
import { ReactComponent as Apartment1908 } from "../../../../static/images/contents/reve-tower/polygons/1908.svg";

export const reveTowerStops = (changeContent) => [
    {
        index: 3,
        wrapper: <span>Wrapper 2</span>,
    },
    {
        index: 36,
        wrapper: <span>Wrapper 3</span>,
    },
    {
        index: 57,
        default: true,
        wrapper: (
            <SvgAreas
                areas={[
                    {
                        Polygon: Apartment1904,
                        className: "area-apartment",
                        infoboxInner: <p>Will be soon</p>,
                    },
                    {
                        Polygon: Apartment1905,
                        className: "area-apartment",
                        infoboxInner: <p>Will be soon</p>,
                    },
                    {
                        Polygon: Apartment1906,
                        className: "area-apartment",
                        infoboxInner: <p>Will be soon</p>,
                    },
                    {
                        Polygon: Apartment1907,
                        className: "area-apartment",
                        infoboxInner: <p>Will be soon</p>,
                    },
                    {
                        Polygon: Apartment1908,
                        className: "area-apartment",
                        infoboxInner: <p>Will be soon</p>,
                    },
                ]}
            />
        ),
    },
    {
        index: 95,
        wrapper: <span>Wrapper 4</span>,
    },
];
