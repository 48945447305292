import { motion, AnimatePresence } from "framer-motion";

import "./Loading.scss";
import { loading_animation, loading_image_animation } from "./animations";

import TextLogoImage from "../../static/images/text_logo.svg";

export default function Loading() {
    return (
        <motion.div className="loading" {...loading_animation}>
            <motion.img
                className="loading-image"
                src={TextLogoImage}
                alt="text-logo"
                {...loading_image_animation}
            />
        </motion.div>
    );
}
