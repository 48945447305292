import { useMemo, useState } from "react";
import { useMediaQuery } from "@react-hook/media-query";
import { AnimatePresence } from "framer-motion";

import "./Reel.scss";
import "./Adaptations.scss";

import Overlay from "../../../layouts/overlay/Overlay";
import Controls from "./controls/Controls";
import Frame from "../frame/Frame";
import ImagesLoader from "./images-loader/ImagesLoader";
import Loading from "./loading/Loading";
import GuideButton from "../menu/icon-button/GuideButton";

export default function Reel({
    folder,
    amount,
    extension = ".jpg",
    qualitySteps,
    defaultControlsPart = 0,
    stops,
    overlay_elements,
}) {
    const isMobile = useMediaQuery("(max-width: 800px)");

    const defaultIndex = useMemo(
        () => stops.find((stop) => stop.default).index,
        [stops]
    );
    const [currentIndex, setCurrentIndex] = useState(defaultIndex);

    const [images, setImages] = useState([]);
    const [loadedPart, setLoadedPart] = useState(0);
    const loadingPercents = useMemo(() => loadedPart * 100, [loadedPart]);

    function getStopIndexes(stops) {
        let indexes = [];
        for (let stop of stops) {
            indexes.push(stop.index);
        }
        return indexes;
    }
    const stop_indexes = useMemo(() => getStopIndexes(stops), [stops]);

    function checkIfStop(index) {
        return stop_indexes.includes(index);
    }

    function nextStop(direction) {
        const intervalStop = setInterval(() => {
            setCurrentIndex((state) => {
                let newState;
                if (state === amount && direction > 0) {
                    newState = 1;
                } else if (state === 1 && direction < 0) {
                    newState = amount;
                } else {
                    newState = state + direction;
                }
                if (checkIfStop(newState)) {
                    clearInterval(intervalStop);
                }

                return newState;
            });
        }, 2400 / amount);
    }

    function findClosestStop(index) {
        let stops = [];
        for (let stop_index of stop_indexes) {
            stops.push({
                index: stop_index,
                difference: stop_index - index,
                inverted_difference: amount + stop_index - index,
            });
        }

        function getMinStepsAmountAndDirection(
            difference,
            inverted_difference
        ) {
            let difference_abs = Math.abs(difference);
            let inverted_difference_abs = Math.abs(inverted_difference);

            function getDirection(number) {
                if (number > 0) {
                    return 1;
                } else if (number < 0) {
                    return -1;
                } else {
                    return 0;
                }
            }

            if (difference_abs <= inverted_difference_abs) {
                return [difference_abs, getDirection(difference)];
            } else {
                return [
                    inverted_difference_abs,
                    getDirection(inverted_difference),
                ];
            }
        }

        for (let stop of stops) {
            let [min_steps, direction] = getMinStepsAmountAndDirection(
                stop.difference,
                stop.inverted_difference
            );

            let score = 0;
            for (let stop_check of stops) {
                if (
                    min_steps <= Math.abs(stop_check.difference) &&
                    min_steps <= Math.abs(stop_check.inverted_difference)
                ) {
                    score += 1;
                }
            }

            if (score === stops.length) {
                stop.direction = direction;
                return stop;
            }
        }
    }

    function goToClosestStop(index) {
        let closestStop = findClosestStop(currentIndex);
        nextStop(closestStop.direction);
    }

    let startX = 0;
    const [isGrabbing, setIsGrabbing] = useState(false);

    const handleMouseDown = (e) => {
        startX = e.clientX;
        document.addEventListener("mousemove", handleMouseMove);
        document.addEventListener("mouseup", handleMouseUp);

        setIsGrabbing(true);
    };

    const handleMouseUp = () => {
        document.removeEventListener("mousemove", handleMouseMove);
        document.removeEventListener("mouseup", handleMouseUp);

        setIsGrabbing(false);
    };

    let deltaXRest = 0;
    let neededDistance = 1200 / amount;

    const handleMouseMove = (e) => {
        if (
            (e.clientX - startX < 0 && deltaXRest > 0) ||
            (e.clientX - startX > 0 && deltaXRest < 0)
        ) {
            deltaXRest = 0;
        }

        const deltaX = e.clientX - startX + deltaXRest;
        const moveAmount = Math.trunc(deltaX / neededDistance);

        if (moveAmount !== 0) {
            deltaXRest = 0;

            setCurrentIndex((state) => {
                let newState;
                newState = state - moveAmount;
                if (newState > amount) {
                    newState = newState - amount;
                } else if (newState <= 0) {
                    newState = newState + amount;
                }
                return newState;
            });
        }

        startX = e.clientX;
        deltaXRest = deltaXRest + (deltaX % neededDistance);
    };

    return (
        <div className="reel">
            <ImagesLoader
                images={images}
                setImages={setImages}
                folder={folder}
                amount={amount}
                extension={extension}
                qualitySteps={qualitySteps}
                checkIfHighByDefault={checkIfStop}
                setLoadedPart={setLoadedPart}
            />
            <AnimatePresence mode="wait">
                {loadingPercents * qualitySteps < 100 && (
                    <Loading percents={loadingPercents * qualitySteps} />
                )}
            </AnimatePresence>
            <div
                className="reel-frame"
                style={{ cursor: isGrabbing ? "grabbing" : "grab" }}
                {...(!isMobile && {
                    onMouseDown: handleMouseDown,
                    onMouseUp: () => goToClosestStop(currentIndex),
                })}
            >
                <Overlay>
                    <AnimatePresence mode="wait">
                        {loadingPercents * qualitySteps >= 100 && (
                            <>
                                {overlay_elements}
                                <div className="reel-menu">
                                    <Controls
                                        defaultIndex={defaultIndex}
                                        defaultPart={defaultControlsPart}
                                        currentIndex={currentIndex}
                                        amount={amount}
                                        nextStop={nextStop}
                                    />
                                    <GuideButton />
                                </div>
                            </>
                        )}
                    </AnimatePresence>
                </Overlay>
                <Frame
                    image={images[currentIndex - 1]}
                    wrapper={
                        !isGrabbing && loadingPercents * qualitySteps >= 100
                            ? stops.find((stop) => stop.index === currentIndex)
                                  ?.wrapper
                            : null
                    }
                />
            </div>
        </div>
    );
}
