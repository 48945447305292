import AzureTower from "../../pages/main/contents/azure-tower/AzureTower";
import MasterPlan from "../../pages/main/contents/master-plan/MasterPlan";
import Phase3 from "../../pages/main/contents/phase-3/Phase3";
import Phase4 from "../../pages/main/contents/phase-4/Phase4";
import ReveTower from "../../pages/main/contents/reve-tower/ReveTower";

export const buttons = [
    {
        title: "menu_master_plan",
        elements: [
            {
                content: <MasterPlan />,
            },
        ],
    },
    {
        title: "menu_phases",
        elements: [
            {
                label: "menu_phases_phase_3",
                content: <Phase3 />,
            },
            {
                label: "menu_phases_phase_4",
                content: <Phase4 />,
            },
        ],
    },
    {
        title: "menu_towers",
        elements: [
            {
                label: "menu_towers_reve",
                content: <ReveTower />,
            },
            {
                label: "menu_towers_azure",
                content: <AzureTower />,
            },
        ],
    },
    {
        title: "menu_unit_types",
        elements: [
            {
                content: <span>Unit Types</span>,
                head_title: "menu_unit_types_headtitle",
            },
        ],
    },
    {
        title: "menu_unit_plans",
        disabled: true,
        elements: [
            {
                content: <span>Unit Types</span>,
            },
        ],
    },
    {
        title: "menu_floor_plans",
        elements: [
            {
                label: "menu_floor_plans_reve1",
                content: <span>RÊVE</span>,
            },
            {
                label: "menu_floor_plans_reve2",
                content: <span>Azure</span>,
            },
            {
                label: "menu_floor_plans_reve3",
                content: <span>Azure</span>,
            },
            {
                label: "menu_floor_plans_azure",
                content: <span>Azure</span>,
            },
        ],
    },
    {
        title: "menu_enquire_now",
        elements: [
            {
                content: <span>Enquire Now</span>,
            },
        ],
    },
];
