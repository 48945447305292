import ApartmentInfo from "../../../../components/infobox/apartment-info/ApartmentInfo";
import SvgAreas from "../../../../components/main/wrappers/svg-areas/SvgAreas";

import { ReactComponent as Apartment801 } from "../../../../static/images/contents/azure-tower/polygons/801.svg";
import { ReactComponent as Apartment802 } from "../../../../static/images/contents/azure-tower/polygons/802.svg";

import Apartment801PlanImage from "../../../../static/images/contents/azure-tower/floor-plans/801.png";
import Apartment802PlanImage from "../../../../static/images/contents/azure-tower/floor-plans/802.png";

export const azureTowerStops = (changeContent) => [
    {
        index: 26,
        default: true,
        wrapper: (
            <SvgAreas
                areas={[
                    {
                        Polygon: Apartment801,
                        className: "area-apartment",
                        infoboxInner: (
                            <ApartmentInfo
                                title="1 bedroom"
                                image={Apartment801PlanImage}
                                specs={[
                                    {
                                        name: "Status",
                                        value: "available",
                                        isHighlighted: true,
                                    },
                                    {
                                        name: "Floor Number",
                                        value: "8",
                                    },
                                    {
                                        name: "Size",
                                        value: "7732.21 sqft",
                                    },
                                    {
                                        name: "Type",
                                        value: "1 BR PENTHOUSE",
                                    },
                                    {
                                        name: "Unit Number",
                                        value: "801",
                                    },
                                ]}
                                buttonOnClick={() =>
                                    changeContent(<h1>Plan 801</h1>, {
                                        button: "menu_unit_plans",
                                        element: null,
                                        head_title: "1 bedroom",
                                    })
                                }
                            />
                        ),
                        isInfoboxOnMobile: true,
                        onClick: () =>
                            changeContent(<h1>Plan</h1>, {
                                button: "menu_unit_plans",
                                element: null,
                                head_title: "1 bedroom",
                            }),
                    },
                    {
                        Polygon: Apartment802,
                        className: "area-apartment",
                        infoboxInner: (
                            <ApartmentInfo
                                title="1 bedroom"
                                image={Apartment802PlanImage}
                                specs={[
                                    {
                                        name: "Status",
                                        value: "available",
                                        isHighlighted: true,
                                    },
                                    {
                                        name: "Floor Number",
                                        value: "8",
                                    },
                                    {
                                        name: "Size",
                                        value: "7681.73 sqft",
                                    },
                                    {
                                        name: "Type",
                                        value: "1 BR PENTHOUSE",
                                    },
                                    {
                                        name: "Unit Number",
                                        value: "802",
                                    },
                                ]}
                                buttonOnClick={() =>
                                    changeContent(<h1>Plan 802</h1>, {
                                        button: "menu_unit_plans",
                                        element: null,
                                        head_title: "1 bedroom",
                                    })
                                }
                            />
                        ),
                        isInfoboxOnMobile: true,
                        onClick: () =>
                            changeContent(<h1>Plan</h1>, {
                                button: "menu_unit_plans",
                                element: null,
                                head_title: "1 bedroom",
                            }),
                    },
                ]}
            />
        ),
    },
    {
        index: 56,
        wrapper: <span>Wrapper 2</span>,
    },
    {
        index: 89,
        wrapper: <span>Wrapper 3</span>,
    },
    {
        index: 114,
        wrapper: <span>Wrapper 4</span>,
    },
];
