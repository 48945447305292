import "./SvgLabel.scss";

export default function SvgLabel({ coords, text, width }) {
    return (
        <g className="svg-label">
            <rect
                className="svg-label-background"
                x={coords[0]}
                y={coords[1] - 15}
                width={width ? width : 8.75 * text.length + 16}
                height="23"
                rx="6"
                ry="6"
            />
            <text
                className="svg-label-text"
                x={coords[0] + 8}
                y={coords[1] + 1}
            >
                {text}
            </text>
        </g>
    );
}
