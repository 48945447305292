import { useMemo } from "react";
import { motion } from "framer-motion";

import "./Controls.scss";
import { controls_animation, controls_button_animation } from "./animations";

import { ReactComponent as ArrowLeftSVG } from "../../../../static/images/icons/reel_controls_left.svg";
import { ReactComponent as ArrowRightSVG } from "../../../../static/images/icons/reel_controls_right.svg";
import CompassImage from "../../../../static/images/icons/reel_controls_compass.png";

function ControlsButton({ ArrowSVG, nextStop, direction }) {
    return (
        <motion.div
            className="controls-button"
            onClick={() => nextStop(direction)}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            {...controls_button_animation}
        >
            <ArrowSVG />
        </motion.div>
    );
}

function ControlsCompassImage() {
    return (
        <img
            className="controls-compass-image"
            alt="compass"
            src={CompassImage}
        />
    );
}

function ControlsCompass({ defaultIndex, defaultPart, currentIndex, amount }) {
    const zeroPart = useMemo(
        () => defaultPart - defaultIndex / amount,
        [defaultPart, defaultIndex, amount]
    );

    return (
        <div className="controls-compass">
            <div
                style={{
                    transform: `translateX(-${
                        387 * (1 + zeroPart + currentIndex / amount)
                    }px)`,
                }}
                className="controls-compass-inner"
            >
                <ControlsCompassImage />
                <ControlsCompassImage />
                <ControlsCompassImage />
            </div>
        </div>
    );
}

export default function Controls({
    defaultIndex,
    defaultPart,
    currentIndex,
    amount,
    nextStop,
}) {
    return (
        <motion.div className="controls" {...controls_animation}>
            <ControlsButton
                ArrowSVG={ArrowLeftSVG}
                nextStop={nextStop}
                direction={-1}
            />
            <ControlsCompass
                defaultIndex={defaultIndex}
                defaultPart={defaultPart}
                currentIndex={currentIndex}
                amount={amount}
            />
            <ControlsButton
                ArrowSVG={ArrowRightSVG}
                nextStop={nextStop}
                direction={1}
            />
        </motion.div>
    );
}
